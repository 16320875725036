/* RatingScore.css */

.rating-score {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50px;
    height: 50px;
  }
  
  .star {
    position: relative;
    display: inline-block;
    width: 90%;
    height: 90%;
    background-color: #FFC100;
    clip-path: polygon(50% 0%, 62% 33%, 98% 35%, 70% 55%, 79% 91%, 50% 72%, 21% 91%, 30% 55%, 2% 35%, 37% 33%);

  }
  
  .rating-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: bold;
    color: #fff;
  }