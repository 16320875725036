/* index.css */

/* Reset some default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
  background: #f5f5f5;
}

.modal-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: #f8f9fa; /* Changed to a soft color */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  width: 80%;
  height: auto;
  max-height: 80%;
}

.modal-container.mobile {
  flex-direction: column;
  max-width: 95%;
  width: 95%;
  padding: 10px;
}

.poster {
  height: 100%;
  max-height: 400px;
  border-radius: 8px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
      display: none; /* Hide poster on mobile */
  }
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 20px;
  overflow-y: auto;
}

.info-container.mobile {
  padding-left: 0;
}

.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 8px 0;
  font-size: 0.9rem;
  color: #555;
  background-color: #e0e0e0ab; /* Background color */
  padding: 8px;
  border-radius: 4px;
}

.overview {
  text-align: left;
  margin: 16px 0;
  max-height: 200px; /* Set a max height for the overview */
  overflow-y: auto; /* Make it scrollable */
}

.imdb-link {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: bold;
  color: #f5c518; /* IMDb yellow color */
  text-decoration: none;
  border: 1px solid #f5c518; /* Add a border to IMDb link */
  padding: 2px 6px; /* Add some padding */
  border-radius: 4px; /* Add rounded corners */
  
  &:hover {
      text-decoration: underline;
  }
}

/* Center the main container */
#root {
  display: flex;
  justify-content: center;
  padding: 10px;
}

/* Add some padding to the h1 */
h1 {
  margin-bottom: 20px;
  font-size: 2rem;
}

/* Adjust the font size and spacing for movie cards */
.movie-card h3 {
  font-size: 1rem;
  margin: 8px 0;
  color: #333;
  text-align: center;
}

.genre {
  font-size: 0.9rem;
  color: #777;
  text-align: center;
  margin-top: 4px;
}

.release-year {
  font-size: 0.8rem;
  color: #aaa;
  margin-left: 8px;
}

/* Adjust padding and margins for a cleaner look */
.container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  width: 80%;
  height: auto;
  max-height: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
}

.modal-content.mobile {
  flex-direction: column;
  max-width: 95%;
  width: 95%;
  padding: 10px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}

.modal-title {
  font-size: 1.5rem;
  margin: 0;
}

.modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal-body .info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.modal-body .info div {
  background-color: #e0e0e0ab;
  padding: 8px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  flex-grow: 1;
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  color: #555;
}

.modal-body .info div.imdb-link {
  background-color: transparent;
  border: 1px solid #f5c518;
  color: #f5c518;
  padding: 4px;
}

.modal-body .overview {
  text-align: left;
  margin-top: 16px;
  max-height: 200px;
  overflow-y: auto;
}

.modal-body .sub-info {
  font-size: 0.9rem;
  color: #777;
  margin: 4px 0;
  text-align: left;
}

/* Styles for smaller screens */
@media (max-width: 768px) {
  .modal-content {
    flex-direction: column;
  }

  .modal-content .poster {
    display: none;
  }

  .info-container {
    padding-left: 0;
  }
}

.language-switcher {
  position: fixed;
  background: #fff;
  cursor: pointer;
}